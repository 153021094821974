import React, {useEffect} from "react";
import {ListCharacteristics} from "../components/ListCharacteristics";
import {ICharacteristic} from "../interfaces/ICharacteristic";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Button, Paper, Container, Typography} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {useHttp} from "../hooks/useHttp";
import { routes } from "../helpers/paths";
import {requests} from "../helpers/requests";
import {Loader} from "../components/Loader";
import {$characteristics, initCharacteristics, resetValues} from "../store/CharacteristicsValuesStore";
import {useStore} from "effector-react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            display: 'block',
            margin: theme.spacing(7, 0)
        },
        btn: {
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.primary.dark
            },
            margin: theme.spacing(1, 2)
        },
        divBtn: {
            margin: theme.spacing(3),
            float: "right"
        },
        container: {
            overflowX: "auto",
            width: '100%'
        },
        title: {
            margin: theme.spacing(2, 0, 3, 2)
        },

    }),
);

export const CharacteristicsPage = (): JSX.Element => {
    const characteristics = useStore($characteristics);
    const history = useHistory();
    const classes = useStyles();
    const {request, loading} = useHttp()

    useEffect(() => {
        if (characteristics.length === 0){
            request(requests.characteristics.url, requests.characteristics.method, null, null)
                .then((data) => {
                    initCharacteristics(data.map( (char: any): ICharacteristic => {
                        return {id: char.id, name: char.name, type: char.type, nosologies: char.nosologies, value: 0, priority: 0} }))
                })
                .catch(() => initCharacteristics([]));
        }
    }, []);


    return (
        <div className={classes.main}>
            <Container fixed>
                <Paper className={classes.container} elevation={1}>
                    <Typography className={classes.title} variant={"h5"}>
                        Укажите характеристики пациента и их важность в контексте данного случая:</Typography>

                    {loading? <Loader/> : <ListCharacteristics characteristics={characteristics}/>}
                    <div className={classes.divBtn}>
                        <Button variant="contained"
                                onClick={() => resetValues()}>Сбросить значения</Button>

                        <Button className={classes.btn}
                                variant="contained"
                                onClick={() => (history.push( {
                                        pathname: routes.toResearches,
                                        state: characteristics
                                    }))}>Узнать о исследованиях</Button>
                    </div>
                </Paper>
            </Container>
        </div>

    )
}