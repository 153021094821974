export const requests = {
    characteristics: {
        url: '/characteristics',
        method: 'get'.toUpperCase(),
    },
    characteristicsByNosologyId: {
        url: (nosologyId: number): string => `/characteristics?nosologyId=${nosologyId}`,
        method: 'get'.toUpperCase(),
    },
    searchCharacteristic: {
        url: '/search',
        method: 'post'.toUpperCase(),
    }
};